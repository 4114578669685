let tooltipPlugin = function (selector) {

    const saveToCookie = (ids) => {
        const expirationDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toUTCString();
        document.cookie = `tooltip=${ids}; expires=${expirationDate}; path=/`;
    };

    let $tooltips = document.querySelectorAll(selector);

    [].forEach.call($tooltips, function ($thisTooltip) {

        if(Cookies.get('tooltip') === undefined) {
            $thisTooltip.classList.remove('hide');
            saveToCookie(true);

            let $toolTipButton = $thisTooltip.querySelector('.tooltip--agree');
            $toolTipButton.addEventListener('click', function (e) {
                e.preventDefault();
                $thisTooltip.classList.add('hide');
                e.stopPropagation();
            });
        }
    });
};

tooltipPlugin('.tooltip');

