document.addEventListener('DOMContentLoaded', function() {
  var navWrapper = document.querySelector('.main-navigation__wrapper');
  var mainNav = document.querySelector('#main-nav');
  var desktopClose = document.querySelector('.close-nav--desktop');

  if (mainNav) {
    mainNav.addEventListener('change', function(e) {
      e.stopPropagation();
      toggleNav(mainNav);
    });
  }

  if (navWrapper) {
    navWrapper.addEventListener('click', function(e) {
      if (e.target.classList.contains('main-navigation__wrapper')) {
        mainNav.checked = false;
        toggleNav(mainNav)
      }
    });
  }

  let toggleNav = function(mainNav) {
    if (mainNav.checked) {
      navWrapper.style.visibility = "visible";
      desktopClose.style.visibility = "visible";
    } else {
      desktopClose.style.visibility = "hidden";
      window.setTimeout(function() {
        navWrapper.style.visibility = "hidden";
      }, 1200);
    }
  }

  document.querySelectorAll('.main-nav .toggle, .not-clickable>span').forEach(function(e) {
    e.addEventListener('click', function() {
      if (this.classList.contains('toggle')) {
        this.classList.toggle('toggle-down');
        this.classList.toggle('toggle-up');
        this.nextElementSibling.classList.toggle('active');
      } else {
        let toggleSibling = this.parentElement.nextElementSibling;
        toggleSibling.nextElementSibling.classList.toggle('active');
        toggleSibling.classList.toggle('toggle-down');
        toggleSibling.classList.toggle('toggle-up');
      }
    });
  });

  document.querySelectorAll('form[name="search"] .icon').forEach(function(i) {
    i.addEventListener('click', function(e) {
        const form = e.target.closest('form');
        if (form) {
          form.dispatchEvent(new Event('submit'));
          form.submit();
        }
    });
  });
});