var articlePersonalizationPlugin = function (selector) {

    var $articlePersonalizationElements = document.querySelectorAll(selector);
    
    if($articlePersonalizationElements.length) {
        const saveToProfile = (ids) => {
            if (tp?.pianoId?.isUserValid()) {
                fetch(`${
                    nozmhnHelper.environment() == 'prd' ? 'https://api-eu.piano.io' : 'https://sandbox.piano.io'
                }/id/api/v1/identity/userinfo?aid=${tp.aid}&access_token=${tp.pianoId.getToken()}&lang=de_DE`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                    body: JSON.stringify({
                        uid: tp.pianoId.getUser().uid,
                        form_name: 'article-personalization',
                        custom_field_values: [{
                            field_name: 'portalLocationFavourite',
                            value: ids,
                        }],
                    }),
                });
            }
        };

        // START
        // Bugfix for duplicate cookie
        function deleteCookieByName(cookieName) {
            const cookies = document.cookie.split("; ");

            cookies.forEach(cookie => {
                const [name, value] = cookie.split("=");

                if (name === cookieName) {
                    // Attempt to clear with common paths
                    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
                    console.log('deleteCookie');
                }
            });
        }
        // Usage
        deleteCookieByName("portalLocationFavourite");
        // END

        const getCookie = (name) => {
            return document.cookie.split(';').some(c => {
                return c.trim().startsWith(name + '=');
            });
        };

        const deleteCookie = (name, path, domain) => {
            if( getCookie(name) ) {
                document.cookie = name + "=" +
                    ((path) ? ";path="+path:"")+
                    ((domain)?";domain="+domain:"") +
                    ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
            };
        };

        const saveToCookie = (ids) => {
            const expirationDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toUTCString();
            document.cookie = `portalLocationFavourite=${ids}; expires=${expirationDate};domain=.`+document.location.host+`;path=/`;
        };


        let createButton = (path, id, location, editMode) => {
            const locationButton = document.createElement("a");
            locationButton.setAttribute('href', path);
            locationButton.setAttribute('class', 'article-personalisation--location__button text-color__comet button--nano w-600 typeface-font position-relative button--rounded bg-color__pacific-blue-10 size--m-6 m-r-1 m-b-1 continue-links ignore-hover');
            locationButton.setAttribute('data-id', id);
            locationButton.innerHTML = location + '<i class="icon icon--arrow bg-color__pacific-blue-80 m-r-0"></i><svg class="icon icon-close ' + ((editMode === false) ? "hidden" : "") + '" viewBox="0 0 18 18" role="img" aria-labelledby="title desc"><g transform="translate(-233 -151)"><circle cx="9" cy="9" r="9" transform="translate(233 151)" fill="#0088cb"></circle><g data-name="Gruppe 5119" transform="translate(238.013 156.013)"><rect width="10.336" height="0.94" transform="translate(0 7.309) rotate(-45)" fill="#fff"></rect><rect width="10.336" height="0.94" transform="translate(0.665) rotate(45)" fill="#fff"></rect></g></g></svg>';
            return locationButton;
        };

        let editMode = false;
        let myAreas = [];

        [].forEach.call($articlePersonalizationElements, function ($thisArticlePersonalization, index) {

            var $dropdownElements = $thisArticlePersonalization.querySelectorAll(".dropdown-ap");
            var $dropdownItems = $thisArticlePersonalization.querySelectorAll('.dropdown-item');
            let articlePersonalisationLocation = document.querySelector('.article-personalisation--locations');

            if (Cookies.get('portalLocationFavourite')) {
                myAreas = Cookies.get('portalLocationFavourite').split(",");
            }

            //console.log('maAreas count: ' + myAreas.length)

            if(myAreas.length) {
                document.querySelector('.article-personalisation__wrapper').classList.add('hidden');
                document.querySelector('.icon-place--blue').classList.remove('hidden');
                document.querySelector('.icon-place--grey').classList.add('hidden');
            }

            $thisArticlePersonalization.querySelectorAll('.dropdown-ap .dropdown-header').forEach(function ($thisDropdownHeader) {
                $thisDropdownHeader.addEventListener('click', function () {
                    if (this.parentNode.classList.contains("dropdown-open")) {
                        this.parentNode.classList.remove("dropdown-open");
                    } else {
                        this.parentNode.classList.add("dropdown-open");
                    }
                });
            });

            $thisArticlePersonalization.querySelectorAll('.dropdown-ap .dropdown-item').forEach(function ($thisDropDownItem) {

                if(myAreas.length) {
                    document.querySelector('.edit-locations').classList.remove('hidden');
                    document.querySelector('.icon-place--blue').classList.remove('hidden');
                    document.querySelector('.icon-place--grey').classList.add('hidden');

                    if(myAreas.includes($thisDropDownItem.getAttribute('data-id'))) {
                        $thisDropDownItem.classList.add('hidden');
                        let newLocationButton = createButton($thisDropDownItem.getAttribute('data-path'), $thisDropDownItem.getAttribute('data-id'), $thisDropDownItem.innerText, false);
                        articlePersonalisationLocation.insertAdjacentElement("afterbegin", newLocationButton);

                        let selectedNavItem = document.querySelector('.nav-main--item[data-id="'+ $thisDropDownItem.getAttribute('data-id') +'"]');
                        if(selectedNavItem !== null && selectedNavItem !== undefined) {
                            selectedNavItem.classList.add('nav-main--item__selected');
                        }
                    }
                }

                $thisDropDownItem.addEventListener('click', function (e) {
                    e.preventDefault();

                    var id = this.getAttribute('data-id');
                    var path = this.getAttribute('data-path');
                    var target = e.target.hasOwnProperty('length') ? e.target[0] : e.target;
                    var publication = target.closest('.dropdown-content').previousElementSibling.innerText;
                    var location = target.innerText;
                    const event_ = new CustomEvent('personalArticleBoxSelected', {
                        detail: location + '##' + publication,
                    });

                    document.querySelector('.icon-place--blue').classList.remove('hidden');
                    document.querySelector('.icon-place--grey').classList.add('hidden');

                    let locationButton = createButton(path, id, location, ((editMode === true) ? true : false));

                    this.closest('.dropdown-level-1').querySelectorAll('.dropdown-item').forEach((i) => {
                        i.dataset.selected = '';
                    });

                    this.className += " hidden";
                    //console.log(this);

                    $dropdownElements[0].dispatchEvent(event_);

                    $dropdownItems[index].querySelectorAll('.dropdown-header').forEach(function ($thisDropdownHeader) {
                        if ($thisDropdownHeader.parentNode.classList.contains("dropdown-open")) {
                            $thisDropdownHeader.parentNode.classList.remove("dropdown-open");
                        } else {
                            $thisDropdownHeader.parentNode.classList.add("dropdown-open");
                        }
                    });

                    var dropdownOpen = $articlePersonalizationElements[index].querySelectorAll('.dropdown-open');
                    [].forEach.call(dropdownOpen, function (thisDropdownOpen) {
                        thisDropdownOpen.classList.remove('dropdown-open');
                    });

                    if(myAreas.length === 0) {
                        articlePersonalisationLocation.insertAdjacentElement("afterbegin", locationButton);
                        myAreas[0] = id;
                        deleteCookie('portalLocationFavourite', '/', '.'+document.location.host);
                    } else {
                        articlePersonalisationLocation.insertAdjacentElement("beforeend", locationButton);
                        myAreas[myAreas.length] = id;
                        editMode = true;
                    }

                    if(myAreas.length === 3) {
                        $thisArticlePersonalization.querySelector('.article-personalisation__wrapper').classList.add('hidden');
                    }

                    $dropdownElements[0].querySelector('.dropdown-header .dropdown-header--text').innerText = 'Weiteren Heimatort wählen';

                    document.querySelector('.edit-locations').classList.remove('hidden');
                    if(editMode === false) {
                        document.querySelector('.article-personalisation__wrapper').classList.add('hidden');
                    }

                    let selectedNavItem = document.querySelector('.nav-main--item[data-id="'+ id +'"]');
                    if(selectedNavItem !== null && selectedNavItem !== undefined) {
                        selectedNavItem.classList.add('nav-main--item__selected');
                    }

                    // set piano property
                    //console.log(myAreas);
                    //console.log(myAreas.join(","));
                    deleteCookie('portalLocationFavourite', '/', '.'+document.location.host);
                    saveToCookie(myAreas.join(","));
                    saveToProfile(myAreas.join(","));
                });
            });


            document.querySelector('.edit-locations').addEventListener('click', function () {

                let articlePersonalisationLocationButtons = document.querySelectorAll('.article-personalisation--location__button');
                const iconsClose = document.querySelectorAll('.article-personalisation--locations .icon-close');
                const apLocationButtons = document.querySelectorAll('.article-personalisation--location__button');

                if(apLocationButtons.length === 3) {
                    document.querySelector('.article-personalisation__wrapper').classList.add('hidden');
                } else {
                    document.querySelector('.article-personalisation__wrapper').classList.remove('hidden');
                }

                if(editMode === true) {
                    editMode = false;
                    this.innerText = 'Favoriten bearbeiten';
                    iconsClose.forEach(function (thisIconClose) {
                        thisIconClose.classList.add('hidden');
                        document.querySelector('.article-personalisation__wrapper').classList.add('hidden');
                    });
                } else {
                    editMode = true;
                    this.innerText = 'Bearbeiten beenden';
                    iconsClose.forEach(function (thisIconClose) {
                        thisIconClose.classList.remove('hidden');
                    });
                }

                [].forEach.call(apLocationButtons, function (thisApLocationButton) {

                    thisApLocationButton.addEventListener('click', function (e) {

                        if(editMode === true) {
                            e.preventDefault();
                            const index = myAreas.indexOf(this.getAttribute('data-id'));
                            document.querySelector('.dropdown-item[data-id="'+ this.getAttribute('data-id') +'"]').classList.remove('hidden');

                            let selectedNavItem = document.querySelector('.nav-main--item[data-id="'+ this.getAttribute('data-id') +'"]');
                            if(selectedNavItem !== null && selectedNavItem !== undefined) {
                                selectedNavItem.classList.remove('nav-main--item__selected');
                            }

                            if (index > -1) { // only splice array when item is found
                                myAreas.splice(index, 1);
                                this.remove();
                                deleteCookie('portalLocationFavourite', '/', '.'+document.location.host);
                                saveToCookie(myAreas.join(","));
                                saveToProfile(myAreas.join(","));
                            }
                            if(myAreas.length === 0) {
                                document.querySelector('.edit-locations').classList.add('hidden');
                                document.querySelector('.edit-locations').innerText = 'Favoriten bearbeiten';
                                $dropdownElements[0].querySelector('.dropdown-header .dropdown-header--text').innerText = 'Heimatort wählen';
                                document.querySelector('.icon-place--blue').classList.add('hidden');
                                document.querySelector('.icon-place--grey').classList.remove('hidden');
                                editMode = false;
                                deleteCookie('portalLocationFavourite', '/', '.'+document.location.host);
                            }
                            //console.log(myAreas);
                        }

                        if(myAreas.length < 3) {
                            document.querySelector('.article-personalisation__wrapper').classList.remove('hidden');
                        }
                    });
                });
            });
        });
    }
};

let openLocalMenu = function (selector) {

    let $openLocalMenus = document.querySelectorAll(selector);

    [].forEach.call($openLocalMenus, function ($openLocalMenu) {

        let localMenu = document.querySelector('.article-personalisation__local');
        let apCloseNav = document.querySelector('.article-personalisation--close-nav');

        $openLocalMenu.addEventListener('click', function () {
            if (this.classList.contains("main-header__local--open")) {
                this.classList.remove("main-header__local--open");
                localMenu.classList.remove('article-personalisation__local--open');
            } else {
                this.classList.add('main-header__local--open');
                localMenu.classList.add('article-personalisation__local--open');
            }
        });

        apCloseNav.addEventListener('click', function () {
            if ($openLocalMenu.classList.contains("main-header__local--open")) {
                $openLocalMenu.classList.remove('main-header__local--open');
                localMenu.classList.remove('article-personalisation__local--open');
            }
        });
    });
};

if (document.querySelector('.main-header') !== null) {
    articlePersonalizationPlugin('.article-personalisation__local');
    openLocalMenu('.main-header__local');
}